export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/official_document_handle/my',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '查看',
    apis: [{
      path: '/basis/official_document/detail',
      method: 'GET'
    },
    {
      path: '/basis/official_document_handle/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/official_document_handle/my',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/official_document',
      method: 'POST'
    }]
  }
}