export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title/current',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    },
    {
      path: '/basis/position',
      method: 'GET'
    }]
  }
}